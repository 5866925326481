import React, { memo } from 'react';

import { cx } from 'utils';
import logoCF from 'assets/image/logoCF-black.svg';
import logoCCM from 'assets/image/ccm-black-logo.svg';
import smartcatLogo from 'assets/image/smartcat_logo.png';
import constructor from 'assets/image/all_constructor_logo.svg';

import useCommonContext from 'hooks/useCommonContext';

import './styles.scss';

const logo = process.env.REACT_APP_CCM_LANG === 'en' ? logoCF : logoCCM;
const appCompany = process.env.REACT_APP_CCM_COMPANY;

type IProps = {
  className?: string;
  children: JSX.Element;
};

const MainLogo = () => {
  const common = useCommonContext();
  if (appCompany === 'constr') {
    return (
      <div className="card-auth__logo-wrap mr_10">
        <img src={constructor} alt="logo" />
      </div>
    );
  }

  if (appCompany === 'smartcat') {
    return (
      <div className="card-auth__logo-wrap mr_10">
        <img src={smartcatLogo} alt="logo" />
      </div>
    );
  }

  return (
    <>
      <img className="header-layout__logo mr_10" src={logo} alt="logo" />
      <span className="card-auth__header-text">{common.t<string>('common.appName')}</span>
    </>
  );
};

function AuthCard({ className, children }: IProps) {
  const classNames = cx('card-auth', className);

  return (
    <div className={classNames}>
      <div className="card-auth__header">
        <MainLogo />
      </div>
      <div className="card-auth__content">{children}</div>
    </div>
  );
}

export default memo(AuthCard);
