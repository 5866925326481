import { gql } from '@apollo/client';

export const GET_COMPANY_LEARNING_OBJECT_TYPES = gql`
  query CompanyLearningObjectTypes($companyId: Int) {
    company_learning_object_types(company_id: $companyId) {
      pk
      id
      name
      icon_image
      type
      order
      has_related_artefact_type
    }
  }
`;
