import i18next from 'i18next';

export const getFavicon = (company?: string) => {
  const iconSelf = i18next.language === 'ru' ? '/favicon.ico' : '/cffavicon.ico';
  switch (company) {
    case 'constr':
      return '/constructor_favicon.ico';
    case 'smartcat':
      return '/smartcat_black.ico';
    default:
      return iconSelf;
  }
};
