import { getDescendants, NodeModel } from '@minoru/react-dnd-treeview';
import { omit } from 'lodash';
import moment from 'moment';
import { ProjectTaskAssignees } from 'types/entities/user';
import dayjs from 'dayjs';

import { CustomData } from '../pages/project/components/projectContentTree/types';
import { CustomData as CustomDataPlan } from '../pages/project/components/projectPlanTree/types';
import { ProjectLearningStructureEnum } from '../types/entities';
import { AdminCompanyLearningObjectType, CourseDates } from '../types';
import { IndicatorCourseElement, IndicatorCourseElementGroupType } from '../components/indicator/types';
import { CompanyArtefactRoleAssignments, ProjectUserType } from '../hooks';
import { FormData } from '../pages/project/subPages/plan/components/assigneesDrawer/AssigneesDrawer';
import { ArtefactProductionSequencePayload } from '../api/requests/project';

export const getDepth = (tree?: NodeModel<CustomData>[], id?: number | string, depth = 0): number => {
  const target: NodeModel<CustomData> | undefined = tree?.find((node) => node.id === id);

  if (target) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return getDepth(tree, target?.parent, depth + 1);
  }

  return depth;
};

export const getDataToWidget = (descendants: NodeModel<CustomData>[]): IndicatorCourseElement[] => {
  const temp: any[] = [];

  descendants.forEach((node) => {
    temp.push({
      status: node?.data?.artefactStatus,
      type: node?.data?.learningObjectType?.type,
      progress: node?.data?.progress,
      state: node?.data?.objectState,
    });
  });
  const video = {
    groupType: IndicatorCourseElementGroupType.video,
    items: temp.filter((t) => t.type === AdminCompanyLearningObjectType.type.VIDEO) || [],
  };
  const material = {
    groupType: IndicatorCourseElementGroupType.material,
    items: temp.filter((t) => t.type === AdminCompanyLearningObjectType.type.TEXT) || [],
  };
  const tasks = {
    groupType: IndicatorCourseElementGroupType.tasks,
    items: temp.filter((t) => t.type === AdminCompanyLearningObjectType.type.TASK) || [],
  };
  return !video.items.length && !material.items.length && !tasks.items.length ? [] : [video, material, tasks];
};

export const getPartialTree = (tree: NodeModel[], parentId: NodeModel['id']): NodeModel[] => {
  const nodes = tree.filter((l) => l.parent === parentId);

  const droppableNodes = nodes.filter((n) => n.droppable);
  const nonDroppableNodes = nodes.filter((n) => !n.droppable);

  return [...droppableNodes, ...nonDroppableNodes];
};

export const getIndex = (
  tree: NodeModel[],
  id: NodeModel['id'],
  parentId: NodeModel['id'],
  suffix: string,
  isNext: boolean
): string => {
  const partialTree = getPartialTree(tree, parentId);
  const index = partialTree.map((n) => n.id).indexOf(id) + 1;
  const listText = suffix === '' ? index.toString() : `${index.toString()}.${suffix}`;

  if (parentId === 0) {
    const strArr = listText.split('');
    const updatedStr = strArr
      .map((el, inx) => {
        if (strArr.length === inx + 1) {
          return `${Number(el) + 1}`;
        }
        return el;
      })
      .join('');
    return isNext ? updatedStr : listText;
  }

  const parentNode = tree.find((n) => n.id === parentId);

  if (!parentNode) {
    return '';
  }

  return getIndex(tree, parentNode.id, parentNode.parent, listText, isNext);
};

export const isLastChild = (tree: NodeModel[], node: NodeModel) => {
  const nodes = tree.filter((n) => n.parent === node.parent);

  const droppableNodes = nodes.filter((n) => n.droppable);
  const nonDroppableNodes = nodes.filter((n) => !n.droppable);

  const ids = [...droppableNodes, ...nonDroppableNodes].map((n) => n.id);

  return ids.indexOf(node.id) === ids.length - 1;
};

export const getLastId = (treeData: NodeModel[]) => {
  const reversedArray = [...treeData].sort((a, b) => {
    if (a.id < b.id) {
      return 1;
    } else if (a.id > b.id) {
      return -1;
    }

    return 0;
  });

  if (reversedArray.length > 0) {
    return reversedArray[0].id;
  }

  return 0;
};

export const getSelectedItem = (treeData: NodeModel<CustomData>[], selected: string[]) => {
  return treeData.filter((node) => selected.some((el) => Number(node.id) === Number(el)));
};

export const filteredNodeTree = (treeData: NodeModel<CustomData>[]) => {
  return treeData
    .filter((node) => !node?.data?.is_deleted)
    .filter((node) => node?.data?.is_show || node?.data?.is_extra);
};

export const treeFilterByText = (treeData: NodeModel<CustomData | CustomDataPlan>[], searchText: string) => {
  let temp = [...treeData];

  if (searchText) {
    temp = temp.map((node) => {
      const text = node.text.toLowerCase();

      if (text.includes(searchText.toLowerCase())) {
        return { ...node, data: { ...node.data, is_show: true } };
      } else {
        return { ...node, data: { ...node.data, is_show: false } };
      }
    });
  }
  return temp;
};

export const getPayloadBySaveContentTree = (treeData: NodeModel<CustomData>[]) => {
  return treeData
    .filter((node) => {
      return !(node?.data?.is_deleted && !node.data.saved);
    })
    .filter((node) => !node?.data?.is_extra)
    .map((node) => {
      const partialTree = getPartialTree(treeData, node.parent);
      const index = partialTree.map((n) => n.id).indexOf(node.id) + 1;

      const payload = {
        id: node.id,
        parent_id: node.parent === 0 ? null : node.parent,
        name: node.text,
        description: node?.data?.description || '',
        order: Number(index),
        learning_structure_type: node?.data?.learningStructureType,
        project_learning_outcome_id: node?.data?.projectLearningOutcomeId || null,
        learning_object_type_id: node?.data?.learningObjectType?.id,
        outcome_description: node?.data?.learningOutcomes,
        is_changed: node?.data?.is_changed,
        is_moved: node?.data?.is_moved,
        is_added: node?.data?.is_added,
        is_deleted: node?.data?.is_deleted,
      };

      if (node?.data?.learningStructureType !== ProjectLearningStructureEnum.production_item) {
        //delete payload.artefact_type_id;
      }

      if (!node?.data?.learningOutcomes) {
        delete payload.outcome_description;
      }

      return payload;
    });
};

export const getPayloadBySavePlanTree = (treeData: NodeModel<CustomDataPlan>[]) => {
  const temp: ArtefactProductionSequencePayload[] = [];

  treeData.forEach((node) => {
    if (node?.data?.artefactSequence?.length) {
      const sequences = [...node?.data?.artefactSequence];
      sequences.forEach((sequence) => {
        if (sequence.is_changed) {
          const iterationDeadline = sequence?.project_task?.cached_linked_artefact?.first_iteration_deadline;
          const prodDeadline = sequence?.project_task?.cached_linked_artefact?.production_deadline;

          const executors =
            sequence.current_assignees
              .filter((c) => c.project_user)
              .map((assign) => {
                return {
                  user_role: Number(assign?.by_artefact_role_assignment?.user_role?.id),
                  project_user: Number(assign?.project_user?.id),
                };
              }) || [];

          const payload: ArtefactProductionSequencePayload = {
            id: Number(sequence.id),
            is_turn_off: sequence.is_turn_off,
            executors,
            first_iteration_deadline: iterationDeadline
              ? moment(sequence?.project_task?.cached_linked_artefact?.first_iteration_deadline).format('YYYY-MM-DD')
              : null,
            artefact_production_deadline: prodDeadline
              ? moment(sequence?.project_task?.cached_linked_artefact?.production_deadline).format('YYYY-MM-DD')
              : null,
          };

          temp.push(payload);
        }
      });
    }
  });

  return temp;
};

export const addExtraData = (treeData: NodeModel<CustomData>[], isEdit?: boolean): NodeModel<CustomData>[] => {
  const filtered = treeData.filter((node) => !node?.data?.is_extra);
  if (!isEdit) {
    return filtered;
  }

  const arr: NodeModel<CustomData>[] = [];
  filtered.forEach((node: NodeModel<CustomData>) => {
    const isLastItem = isLastChild(filtered, node);
    const depth = getDepth(filtered, node.id);
    const hasChild = !!filtered.find((n) => n.parent === node.id);

    // if (!hasChild && depth === 4) {
    //   const extraData = {
    //     id: 777 + Number(node.id),
    //     parent: node.id,
    //     droppable: false,
    //     text: '',
    //     data: {
    //       is_extra: true,
    //       artefactTypeId: null,
    //       learningStructureType: ProjectLearningStructureEnum.production_item,
    //     },
    //   };
    //   arr.push(extraData);
    // }
    //
    // if (!hasChild && depth !== 4) {
    //   const extraData = {
    //     id: 777 + Number(node.id),
    //     parent: node.id,
    //     droppable: false,
    //     text: '',
    //     data: {
    //       is_extra: true,
    //       artefactTypeId: null,
    //       learningStructureType: null,
    //     },
    //   };
    //   arr.push(extraData);
    // }

    if (isLastItem) {
      const extraData = {
        id: 999 + Number(node.id),
        parent: depth === 1 ? 0 : node.parent,
        droppable: false,
        text: '',
        data: {
          is_extra: true,
          artefactTypeId: null,
          learningStructureType: node.data?.learningStructureType,
        },
      };

      arr.push(node);
      arr.push(extraData);
    } else {
      arr.push(node);
    }
  });
  return arr;
};

const getNodeSequenceIds = (node?: NodeModel<CustomDataPlan>): string[] => {
  if (node?.data?.artefactSequence?.length) {
    return node?.data?.artefactSequence?.map((s) => s.id || '') || [];
  }
  return [];
};

type DescendantsWithSequence = {
  allIds: string[];
  onlySequenceIds: string[];
};

export const getDescendantsWithSequence = (
  tree: NodeModel<CustomDataPlan>[],
  artId: number
): DescendantsWithSequence => {
  const node = tree.find((n) => n.id === artId);

  const descendants: NodeModel<CustomDataPlan>[] = getDescendants(tree, Number(artId));
  const descendantsIds: string[] = getDescendants(tree, Number(artId)).map((n) => n.id.toString());
  let descendantsSequenceIds: string[] = [];

  const nodeSequenceIds = getNodeSequenceIds(node);

  descendants.forEach((n) => {
    const t = getNodeSequenceIds(n);
    descendantsSequenceIds = [...descendantsSequenceIds, ...t];
  });

  return {
    allIds: [...nodeSequenceIds, ...descendantsIds, ...descendantsSequenceIds],
    onlySequenceIds: [...nodeSequenceIds, ...descendantsSequenceIds],
  };
};

export const updateSequences = (
  tree: NodeModel<CustomDataPlan>[],
  selected: string[],
  values: FormData,
  projectUsers?: ProjectUserType[],
  artefactRoleAssignments?: CompanyArtefactRoleAssignments[]
) => {
  return tree.map((node) => {
    if (node?.data?.artefactSequence?.length) {
      const sequences = [...node?.data?.artefactSequence];
      const tempSequences = sequences.map((sequence) => {
        if (selected.includes(sequence.id)) {
          // Deadlines
          const projectTask = {
            ...sequence?.project_task,
            cached_linked_artefact: {
              ...sequence?.project_task?.cached_linked_artefact,
              production_deadline: values.artefact_production_deadline
                ? dayjs(values.artefact_production_deadline).format('YYYY-MM-DD')
                : sequence?.project_task?.cached_linked_artefact.production_deadline,
              first_iteration_deadline: values.artefact_iteration_deadline
                ? dayjs(values.artefact_iteration_deadline).format('YYYY-MM-DD')
                : sequence?.project_task?.cached_linked_artefact.first_iteration_deadline,
            },
          };

          //Assignees
          const onlyAssign = omit(values, ['artefact_iteration_deadline', 'artefact_production_deadline']);
          const assignees: ProjectTaskAssignees[] = [...sequence?.current_assignees];

          Object.keys(onlyAssign).forEach((el) => {
            const currentArtRoleAssignment = artefactRoleAssignments?.find(
              (artAssign) =>
                artAssign.company_artefact_type.id === sequence.project_task.cached_linked_artefact.artefact_type.id
            );
            const hasRole = currentArtRoleAssignment?.user_roles.find((r) => r.id === el);
            if (hasRole) {
              const companyUserId = values[el] as string;
              const user = projectUsers?.find((u) => u.company_user.id === companyUserId);

              if (user) {
                const newAssign = {
                  project_task: {
                    id: sequence?.project_task.id,
                  },
                  is_active: true,
                  is_current_executor: true,
                  by_artefact_role_assignment: {
                    user_role: hasRole,
                  },
                  project_user: {
                    id: user.id,
                    is_active: user.is_active,
                    roles: user.roles,
                    company_user: user.company_user,
                  },
                };
                const index = assignees.findIndex(
                  (assignItem) => assignItem.by_artefact_role_assignment?.user_role.id === hasRole.id
                );

                if (index !== -1) {
                  assignees.splice(index, 1, newAssign);
                } else {
                  assignees.push(newAssign);
                }
              }
            }
          });

          const uniqAssignees = assignees.filter(
            (elem: ProjectTaskAssignees, index: number) =>
              assignees.findIndex(
                (item: ProjectTaskAssignees) =>
                  item?.project_user?.company_user?.user?.id === elem?.project_user?.company_user?.user?.id
              ) === index
          );
          return {
            ...sequence,
            is_changed: true,
            current_assignees: uniqAssignees,
            project_task: projectTask,
          };
        }
        return sequence;
      });

      return {
        ...node,
        data: {
          ...node.data,
          artefactSequence: [...tempSequences],
        },
      };
    }
    return node;
  });
};

export const toggleTaskTurnOff = (tree: NodeModel<CustomDataPlan>[], sequenceId: string) => {
  return tree.map((node) => {
    if (node?.data?.artefactSequence?.length) {
      const sequences = [...node?.data?.artefactSequence];

      const tempSequences = sequences.map((s) => {
        if (s.id === sequenceId) {
          return {
            ...s,
            is_changed: true,
            is_turn_off: !s.is_turn_off,
          };
        }
        return s;
      });

      return {
        ...node,
        data: {
          ...node.data,
          artefactSequence: [...tempSequences],
        },
      };
    }
    return node;
  });
};

export const getOnlySequencesMap = (selected: string[], map: string[]) => {
  return selected.filter((s) => map.some((m) => s === m));
};
